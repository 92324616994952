/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Redux Imports */

import StateInterface from "../../../../redux-magic/state-interface";
import { SearchFilterId } from "../../../../redux-magic/sub-interfaces/search-types";
import { updateSearchFilterAppliedThunk, updateSearchFilterSelectedOptionsThunk } from "../../../../redux-magic/thunks";

/* Component Imports */

import { InputAdornment, Slider, TextField, Typography } from "@mui/material";
import { useRouter } from "next/router";

/* Library Function Imports */

// import CurrencyToNumberPipe from "../../../../lib/CurrencyToNumberPipe";
// import NumberToCurrencyPipe from "../../../../lib/NumberToCurrencyPipe";
import PreparePriceString from "../../../../lib/PreparePriceString";

/* Styled Components */

const ContentContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	flexWrap: "wrap",
	margin: "0rem",
	gap: "0.5rem",
	[theme.breakpoints.up("md1190")]: {
		// padding: "0rem 1rem 1rem 1rem",
		gap: "1rem",
	},
}));

const TextFieldsContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	flexWrap: "nowrap",
	width: "100%",
	gap: "0.5rem",
	padding: "0rem 0rem 0rem 0rem",
	margin: "2rem 0rem 0rem 0rem",
}));

const CustomSlider = styled(Slider)(({ theme }) => ({
	width: "90%",
	margin: "1.5rem 0rem 2rem 0rem",
	"& .MuiSlider-track": {
		border: "none",
		height: 4,
	},
	"& .MuiSlider-thumb": {
		height: 28,
		width: 28,
		backgroundColor: "#fff",
		border: "2px solid currentColor",
		"&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
			boxShadow: "inherit",
		},
		"&::before": {
			display: "none",
		},
	},
	"& .MuiSlider-valueLabel": {
		lineHeight: 1.2,
		fontSize: 14,
		background: "unset",
		padding: 0,
		width: "4rem",
		height: "2rem",
		borderRadius: "0.5rem",
		backgroundColor: "#ffe9d9",
		color: "#5f2b01",
	},
}));

const Text = styled(TextField)(({ theme }) => ({
	width: "100%",
	"& .MuiOutlinedInput-root": {
		borderRadius: "0.5rem",
		fontSize: "0.875rem",
		fontWeight: "400",
		// lineHeight: "1rem",
		// height: "3.35rem",
	},
}));

const SubText = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	fontWeight: "500",
	lineHeight: "1rem",
	padding: "0rem 0rem 1.5rem 0rem",
	color: theme.palette.text.secondary,
}));

const BudgetComponent = ({
	search_id,
	dispatch,
	search_filter_state,
	transaction_type,
}: {
	search_id: SearchFilterId;
	dispatch: Function;
	search_filter_state: StateInterface["search_filters_state"]["budget"];
	transaction_type: StateInterface["search_filters_state"]["transaction_type"];
}) => {
	const router = useRouter();

	const [source, setSource] = React.useState<string>("");

	const [minBudget, setMinBudget] = React.useState<number>(0);
	const [maxBudget, setMaxBudget] = React.useState<number>(0);

	const [errorMinPrice, setErrorMinPrice] = React.useState<boolean>(false);
	const [errorMaxPrice, setErrorMaxPrice] = React.useState<boolean>(false);

	const [minBudgetText, setMinBudgetText] = React.useState<number>(0);
	const [maxBudgetText, setMaxBudgetText] = React.useState<number>(0);

	React.useEffect(() => {
		setMinBudget(0);
		setMaxBudget(0);
		setMinBudgetText(0);
		setMaxBudgetText(0);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [transaction_type.selected_options[0]?.id]);

	/* Setting initial values for budget */

	React.useEffect(() => {
		if (source !== "main_filter") {
			setMinBudget(
				search_filter_state.is_applied &&
					typeof search_filter_state.selected_options[0]?.value === "object" &&
					"min" in search_filter_state.selected_options[0]?.value
					? decalculateValue(search_filter_state.selected_options[0].value.min)
					: 0,
			);
			setMaxBudget(
				search_filter_state.is_applied &&
					typeof search_filter_state.selected_options[0]?.value === "object" &&
					"max" in search_filter_state.selected_options[0]?.value
					? decalculateValue(search_filter_state.selected_options[0].value.max)
					: 0,
			);
			setMinBudgetText(
				search_filter_state.is_applied &&
					typeof search_filter_state.selected_options[0]?.value === "object" &&
					"min" in search_filter_state.selected_options[0]?.value
					? search_filter_state.selected_options[0].value.min
					: 0,
			);
			setMaxBudgetText(
				search_filter_state.is_applied &&
					typeof search_filter_state.selected_options[0]?.value === "object" &&
					"max" in search_filter_state.selected_options[0]?.value
					? search_filter_state.selected_options[0].value.max
					: 0,
			);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search_filter_state]);

	/* Formatting slider value label */

	function valueLabelFormat(value: number) {
		return `₹${PreparePriceString(value)}`;
	}

	/* Calculating slider scaling value */

	function calculateValue(value: number) {
		if (transaction_type.selected_options[0]?.id === "rent") {
			//scale double after 1 L, 1 L should be in middle

			if (value < 325000) {
				return parseInt((value * 0.4).toFixed(0));
			} else if (value >= 325000) {
				return Math.floor(((value - 250000) * 5) / 2.5);
			} else {
				return parseInt(value.toFixed(0));
			}
		} else {
			//scale double after 2 cr, 2 cr should be in middle

			if (value < 50000000) {
				return parseInt((value * 0.4).toFixed(0));
			} else if (value >= 50000000) {
				return Math.floor(((value - 30000000) * 10) / 7);
			} else {
				return parseInt(value.toFixed(0));
			}
		}
	}

	/* De-calculating slider scaling value */

	function decalculateValue(value: number) {
		if (transaction_type.selected_options[0]?.id === "rent") {
			if (value < 130000) {
				return parseInt((value / 0.4).toFixed(0));
			} else if (value >= 150000) {
				return Math.floor((value * 2.5) / 5 + 250000);
			} else if (value >= 130000) {
				return Math.floor((value * 2.55) / 5 + 250000);
			} else {
				return parseInt(value.toFixed(0));
			}
		} else {
			if (value < 20000000) {
				return parseInt((value / 0.4).toFixed(0));
			} else if (value === 20000000) {
				return value + 29999999;
			} else if (value > 20000000) {
				return Math.floor((value * 7) / 10 + 30000000);
			} else {
				return parseInt(value.toFixed(0));
			}
		}
	}

	/* Slider markers (Sale) */

	const sale_markers = [
		{
			value: 0,
			scaledValue: 0,
			label: "0",
		},
		{
			value: 50000000,
			label: "2Cr",
		},
		{
			value: 100000000,
			label: "10Cr+",
		},
	];

	/* Slider markers (Rent) */

	const rent_markers = [
		{
			value: 0,
			scaledValue: 0,
			label: "0",
		},
		{
			value: 250000,
			label: "1L",
		},
		{
			value: 500000,
			label: "5L+",
		},
	];

	const handleUpdateMinBudget = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSource("main_filter");

		const value = e.target.value;
		const numberValue = parseInt(value);

		if (value === "") {
			setMinBudgetText(0);
			setMinBudget(0);
		} else {
			// const numberValue = CurrencyToNumberPipe(value);
			if (!isNaN(numberValue)) {
				setMinBudgetText(numberValue);
				setMinBudget(decalculateValue(numberValue));
			} else {
				setMinBudgetText(0);
				setMinBudget(0);
			}
		}

		if (maxBudgetText !== 0 && numberValue > maxBudgetText) {
			setErrorMinPrice(true);
			setErrorMaxPrice(true);
		} else {
			setErrorMinPrice(false);
			setErrorMaxPrice(false);
		}

		// Dispatch updated budget range
		// handleUpdateMinBudgetDispatch(numberValue);
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: [
					{
						id: "budget",
						title: "Budget",
						value: {
							min: Number.isNaN(numberValue) ? 0 : numberValue,
							max: maxBudgetText,
							unlock_max: maxBudgetText === 0 || Number.isNaN(maxBudgetText) ? true : false,
						},
					},
				],
			}),
		);

		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: search_id,
				isApplied: true,
			}),
		);

		setSource("");
	};

	// const handleUpdateMinBudgetDispatch = debounce((value: number) => {
	// 	dispatch(
	// 		updateSearchFilterSelectedOptionsThunk({
	// 			searchFilterId: search_id,
	// 			newOptions: [
	// 				{
	// 					id: "budget",
	// 					title: "Budget",
	// 					value: {
	// 						min: value,
	// 						max: maxBudget,
	// 						unlock_max: maxBudgetText === 0 ? true : false,
	// 					},
	// 				},
	// 			],
	// 		}),
	// 	);
	// }, 300);

	const handleUpdateMaxBudget = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSource("main_filter");

		const value = e.target.value;
		const numberValue = parseInt(value);

		if (value === "") {
			setMaxBudgetText(0);
			setMaxBudget(0);
		} else {
			if (!isNaN(numberValue)) {
				setMaxBudgetText(numberValue);
				setMaxBudget(decalculateValue(numberValue));
			} else {
				setMaxBudgetText(0);
				setMaxBudget(transaction_type.selected_options[0]?.id === "rent" ? 500000 : 100000000);
			}
		}

		if (minBudgetText !== 0 && numberValue < minBudgetText) {
			setErrorMinPrice(true);
			setErrorMaxPrice(true);
		} else {
			setErrorMinPrice(false);
			setErrorMaxPrice(false);
		}

		// Dispatch updated budget range
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: [
					{
						id: "budget",
						title: "Budget",
						value: {
							min: minBudgetText,
							max: Number.isNaN(numberValue) ? 0 : numberValue,
							unlock_max: numberValue === 0 || Number.isNaN(numberValue) ? true : false,
						},
					},
				],
			}),
		);

		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: search_id,
				isApplied: true,
			}),
		);

		setSource("");
	};

	// React.useEffect(() => {
	// 	if (search_filter_state.selected_options.length === 0) {
	// 		setMinBudget(0);
	// 		setMaxBudget(0);
	// 	}
	// 	const value = search_filter_state.selected_options[0]?.value;

	// 	if (typeof value === "object" && "min" in value) {
	// 		setMinBudget(value.min);
	// 		setMaxBudget(value.max);
	// 		const isApplied = value.min !== 0 || value.max !== 0;
	// 		dispatch(
	// 			updateSearchFilterAppliedThunk({
	// 				searchFilterId: search_id,
	// 				isApplied: isApplied,
	// 			}),
	// 		);
	// 	}

	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [search_filter_state, search_id, dispatch]);

	return (
		<ContentContainer>
			<TextFieldsContainer>
				<Text
					value={minBudgetText === 0 ? "" : minBudgetText}
					// placeholder={
					// 	router.locale === "ar-AE" ? "الحد الأدنى للسعر" : router.locale === "kn-IN" ? "ಕಡಿಮೆ ಬಜೆಟ್" : "Minimum"
					// }
					variant="outlined"
					size="small"
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						handleUpdateMinBudget(e);
					}}
					slotProps={{
						input: {
							startAdornment: (
								<InputAdornment position="start">
									<Typography
										variant="body2"
										color="textSecondary"
										fontWeight={400}
										sx={{ marginBottom: "0.05rem" }}
									>
										{"₹"}
									</Typography>
								</InputAdornment>
							),
						},
						htmlInput: {
							inputMode: "numeric",
							pattern: "[0-9]*",
						},
					}}
					error={errorMinPrice}
					helperText={
						errorMinPrice
							? "Value is more than max budget"
							: minBudgetText === 0
								? "Minimum"
								: PreparePriceString(minBudgetText)
					}
					autoComplete="off"
					onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
						if (event.key === "Enter" || event.key === "Next") {
							(event.target as HTMLInputElement).blur(); // Blur the input field to close the keypad
						}
					}}
				/>

				<SubText>-</SubText>

				<Text
					value={maxBudgetText === 0 ? "" : maxBudgetText}
					// placeholder={
					// 	router.locale === "ar-AE" ? "الحد الأقصى للسعر" : router.locale === "kn-IN" ? "ಗರಿಷ್ಠ ಬಜೆಟ್" : "Maximum"
					// }
					variant="outlined"
					size="small"
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						handleUpdateMaxBudget(e);
					}}
					slotProps={{
						input: {
							startAdornment: (
								<InputAdornment position="start">
									<Typography
										variant="body2"
										color="textSecondary"
										fontWeight={400}
										sx={{ marginBottom: "0.05rem" }}
									>
										{"₹"}
									</Typography>
								</InputAdornment>
							),
						},
						htmlInput: {
							inputMode: "numeric",
							pattern: "[0-9]*",
						},
					}}
					error={errorMaxPrice}
					helperText={
						errorMinPrice
							? "Value is less than min budget"
							: maxBudgetText === 0
								? "Maximum"
								: PreparePriceString(maxBudgetText)
					}
					autoComplete="off"
					onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
						if (event.key === "Enter" || event.key === "Next") {
							(event.target as HTMLInputElement).blur(); // Blur the input field to close the keypad
						}
					}}
				/>
			</TextFieldsContainer>

			<CustomSlider
				valueLabelDisplay="auto"
				marks={transaction_type.selected_options[0]?.id === "rent" ? rent_markers : sale_markers}
				min={0}
				max={transaction_type.selected_options[0]?.id === "rent" ? 500000 : 100000000}
				scale={calculateValue}
				getAriaValueText={valueLabelFormat}
				valueLabelFormat={valueLabelFormat}
				value={[
					minBudget,
					maxBudget === 0 ? (transaction_type.selected_options[0]?.id === "rent" ? 500000 : 100000000) : maxBudget,
				]}
				onChange={(e, value: number | number[]) => {
					if (Array.isArray(value)) {
						setMinBudget(value[0]);
						setMaxBudget(value[1]);
						setMinBudgetText(calculateValue(value[0]));
						setMaxBudgetText(calculateValue(value[1]));
					}
				}}
				onChangeCommitted={(e, value: number | number[]) => {
					setSource("main_filter");

					if (Array.isArray(value)) {
						setMinBudget(value[0]);
						setMaxBudget(value[1]);
						setMinBudgetText(calculateValue(value[0]));
						setMaxBudgetText(calculateValue(value[1]));

						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: search_id,
								newOptions: [
									{
										id: "budget",
										title: "Budget",
										value: {
											min: calculateValue(value[0]),
											max:
												calculateValue(value[1]) ===
												(transaction_type.selected_options[0]?.id === "rent" ? 500000 : 100000000)
													? 0
													: calculateValue(value[1]),
											unlock_max:
												value[1] === 0 ||
												value[1] === (transaction_type.selected_options[0]?.id === "rent" ? 500000 : 100000000)
													? true
													: false,
										},
									},
								],
							}),
						);

						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: search_id,
								isApplied: true,
							}),
						);

						setSource("");
					}
				}}
			/>
		</ContentContainer>
	);
};
export default BudgetComponent;
